import React, { useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import "../App.css";

const Form = () => {
  const form = useRef();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("* First Name is Required"),
      lastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("* Last Name is Required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("* Email is Required"),
      phone: Yup.string()
        .required("* Phone number is required")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Invalid phone number"
        )
        .min(10, "* Phone number is too short")
        .max(10, "* Phone number is too long"),
      message: Yup.string()
        .max(1500, "Must be 1500 characters or less")
        .required("* Message is Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      emailjs
        .sendForm("service_kbrwqug", "template_5t0uaz9", form.current, {
          publicKey: "4NAuHpfU-uWNznBHm",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            resetForm();
            document.getElementById("notification").innerHTML =
              "Message has been sent";
            document.getElementById("notification").style.color = "#fff";
          },
          (error) => {
            console.log("FAILED...", error.text);
            resetForm();
            document.getElementById("notification").innerHTML =
              "Please try again later";
            document.getElementById("notification").style.color = "#fff";
          }
        );
    },
  });

  const handleReset = () => {
    if (!window.confirm("Reset?")) {
      throw new Error("Cancel reset");
    }
  };

  return (
    <section>
      <form ref={form} onSubmit={formik.handleSubmit} onReset={handleReset}>
        <div className="flex flex-col mt-10 mb-12 max-w-[1200px] mx-auto md:px-6 px-4 text-center content-center">
          <input
            className="w-full p-3 border-[#cbcbcb] rounded-md border-[1px] my-2 border-solid text-black"
            type="text"
            id="firstName"
            name="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            placeholder="First Name"
          />

          {formik.touched.firstName && formik.errors.firstName ? (
            <div className="text-[#c72a2a] text-left text-sm">
              {formik.errors.firstName}
            </div>
          ) : null}

          <input
            className="w-full p-3 border-[#cbcbcb] rounded-md border-[1px] my-2 border-solid text-black"
            type="text"
            id="lastName"
            name="lastName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            placeholder="Last Name"
          />

          {formik.touched.lastName && formik.errors.lastName ? (
            <div className="text-[#c72a2a] text-left text-sm">
              {formik.errors.lastName}
            </div>
          ) : null}

          <input
            className="w-full p-3 border-[#cbcbcb] rounded-md border-[1px] my-2 border-solid text-black"
            type="email"
            id="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            placeholder="Email"
          />

          {formik.touched.email && formik.errors.email ? (
            <div className="text-[#c72a2a] text-left text-sm">
              {formik.errors.email}
            </div>
          ) : null}

          <input
            className="w-full p-3 border-[#cbcbcb] rounded-md border-[1px] my-2 border-solid text-black"
            type="phone"
            name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            placeholder="Phone Number"
          />

          {formik.touched.phone && formik.errors.phone ? (
            <div className="text-[#c72a2a] text-left text-sm">
              {formik.errors.phone}
            </div>
          ) : null}

          <textarea
            className="w-full p-3 border-solid border-[1px] my-2 border-[#cbcbcb] rounded-md text-black h-[170px]"
            name="message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
            placeholder="Message"
          />

          {formik.touched.message && formik.errors.message ? (
            <div className="text-[#c72a2a] text-left text-sm">
              {formik.errors.message}
            </div>
          ) : null}

          <button
            type="submit"
            value="Send"
            className="px-3 py-2 my-3 bg-[#1357a9] text-white mx-auto rounded-md w-[120px]"
          >
            Submit
          </button>

          <div
            className="text-center pt-6 text-green-700"
            id="notification"
          ></div>
        </div>
      </form>
    </section>
  );
};

export default Form;
