import React, { useState } from "react";
import About from "./components/About";
import Intro from "./components/Intro";
import Navbar from "./components/Navbar";
import Portfolioarea from "./components/Portfolioarea";
import Skillsbar from "./components/Skillsbar";
import Resume from "./components/Resume";
import Contact from "./components/Contact";
import Form from "./components/Form";
import Footerbar from "./components/Footerbar";

function App() {
  const [theme, setTheme] = useState(false);
  return (
    <div className="bg-white max-w-[1530px] mx-auto">
      <Navbar />
      <Intro />
      <About />
      <Skillsbar />
      <Portfolioarea />
      <Resume />
      <Contact />
      <Form />
      <Footerbar />
    </div>
  );
}

export default App;
