import React, { useEffect, useState, useRef } from "react";
import { BiZoomIn } from "react-icons/bi";
import "./Portfolioarea.css";
import { Image } from "antd";
import "../App.css";

//UI DESIGNS
import FitClub from "../assets/FitClub.jpg";
import FitClub_Cover from "../assets/FitClub_Cover.jpg";
import MedZone from "../assets/MedZone.jpg";
import MedZone_Cover from "../assets/MedZone_Cover.jpg";
import QuickPay from "../assets/QuickPay.jpg";
import QuickPay_Cover from "../assets/QuickPay_Cover.jpg";
import DriveHub from "../assets/DriveHub.jpg";
import DriveHub_Cover from "../assets/DriveHub_Cover.jpg";

//OTHER DESIGNS
import Gtec from "../assets/Gtec.jpg";
import Gtec_Cover from "../assets/Gtec_Cover.jpg";
import Peters from "../assets/Peters.jpg";
import Peters_Cover from "../assets/Peters_Cover.jpg";
import NiceNeat from "../assets/NiceNeat.jpg";
import NiceNeat_Cover from "../assets/NiceNeat_Cover.jpg";
import SmsAd from "../assets/SmsAd.jpg";
import SmsAd_Cover from "../assets/SmsAd_Cover.jpg";
import MakeUp from "../assets/MakeUp.jpg";
import MakeUp_Cover from "../assets/MakeUp_Cover.jpg";
import AngloScape from "../assets/AngloScape.jpg";
import AngloScape_Cover from "../assets/AngloScape_Cover.jpg";
import YMCA from "../assets/YMCA.jpg";
import YMCA_Cover from "../assets/YMCA_Cover.jpg";

//FRONT-END DESIGNS
import Nike_Cover from "../assets/Nike_Cover.jpg";
import DashTwo_Cover from "../assets/DashTwo_Cover.jpg";
import MovieApp_Cover from "../assets/MovieApp_Cover.png";

const Portfolioarea = () => {
  const [isShown, setIsShown] = useState(false);

  const portfolio = [
    {
      name: "FITCLUB",
      content: (
        <div className="cursor-pointer">
          <Image
            className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
            src={FitClub_Cover}
            preview={{
              src: FitClub,
            }}
          />
        </div>
      ),
      link: (
        <span>
          <BiZoomIn />
        </span>
      ),
      category: ["all", "ux-ui"],
    },
    {
      name: "MEDZONE",
      content: (
        <div className="cursor-pointer">
          <Image
            className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
            src={MedZone_Cover}
            preview={{
              src: MedZone,
            }}
          />
        </div>
      ),
      category: ["all", "ux-ui"],
    },
    {
      name: "QUICKPAY",
      content: (
        <div className="cursor-pointer">
          <Image
            className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
            src={QuickPay_Cover}
            preview={{
              src: QuickPay,
            }}
          />
        </div>
      ),
      category: ["all", "ux-ui"],
    },
    {
      name: "DRIVEHUB",
      content: (
        <div className="cursor-pointer">
          <Image
            className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
            src={DriveHub_Cover}
            preview={{
              src: DriveHub,
            }}
          />
        </div>
      ),
      category: ["all", "ux-ui"],
    },
    {
      name: "MOVIE APP",
      content: (
        <div className="cursor-pointer">
          <a href="http://movieapp.deepumathew.com" target="_blank">
            <img
              className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
              src={MovieApp_Cover}
            />
          </a>
        </div>
      ),
      category: ["all", "frontend"],
    },
    {
      name: "NIKE SHOES",
      content: (
        <div className="cursor-pointer">
          <a href="http://nikeshoes.deepumathew.com/" target="_blank">
            <img
              className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
              src={Nike_Cover}
            />
          </a>
        </div>
      ),
      category: ["all", "frontend"],
    },
    {
      name: "WISE ADMIN PANEL",
      content: (
        <div className="cursor-pointer">
          <a href="https://adminpanel02.deepumathew.com/" target="_blank">
            <img
              className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
              src={DashTwo_Cover}
            />
          </a>
        </div>
      ),
      category: ["all", "frontend"],
    },
    {
      name: "GTEC",
      content: (
        <div className="cursor-pointer">
          <Image
            className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
            src={Gtec_Cover}
            preview={{
              src: Gtec,
            }}
          />
        </div>
      ),
      category: ["all", "others"],
    },
    {
      name: "PETERS",
      content: (
        <div className="cursor-pointer">
          <Image
            className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
            src={Peters_Cover}
            preview={{
              src: Peters,
            }}
          />
        </div>
      ),
      category: ["all", "others"],
    },
    {
      name: "SMS FOODS AD",
      content: (
        <div className="cursor-pointer">
          <Image
            className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
            src={SmsAd_Cover}
            preview={{
              src: SmsAd,
            }}
          />
        </div>
      ),
      category: ["all", "others"],
    },
    {
      name: "NICE & NEAT",
      content: (
        <div className="cursor-pointer">
          <Image
            className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
            src={NiceNeat_Cover}
            preview={{
              src: NiceNeat,
            }}
          />
        </div>
      ),
      category: ["all", "others"],
    },
    {
      name: "MAKEUP ACADEMY",
      content: (
        <div className="cursor-pointer">
          <Image
            className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
            src={MakeUp_Cover}
            preview={{
              src: MakeUp,
            }}
          />
        </div>
      ),
      category: ["all", "others"],
    },
    {
      name: "ANGLOSCAPE",
      content: (
        <div className="cursor-pointer">
          <Image
            className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
            src={AngloScape_Cover}
            preview={{
              src: AngloScape,
            }}
          />
        </div>
      ),
      category: ["all", "others"],
    },
    {
      name: "YMCA",
      content: (
        <div className="cursor-pointer">
          <Image
            className="port-image max-w-[300px] border-[1px] border-[#ccc] p-1"
            src={YMCA_Cover}
            preview={{
              src: YMCA,
            }}
          />
        </div>
      ),
      category: ["all", "others"],
    },
  ];

  const [filter, setFilter] = useState("all");
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setProjects(portfolio);
  }, []);

  useEffect(() => {
    setProjects([]);

    const filtered = portfolio.map((p) => ({
      ...p,
      filtered: p.category.includes(filter),
    }));
    setProjects(filtered);
  }, [filter]);

  const portfolio_area = useRef(null);

  return (
    <section id="portfolioarea">
      <div className="py-20 mx-auto w-full max-w-[1250px] px-4">
        <h1 className="mx-auto font-bold text-center text-3xl">PORTFOLIO</h1>
        <p className="py-4 text-lg text-[#7c7c7c] mx-auto text-center">
          creating websites that offer a unique user experience
        </p>

        <div className="w-[330px] font-semibold text-sm md:text-base uppercase flex justify-between text-center px-8 md:px-0 mx-auto my-8">
          <a
            className="cursor-pointer"
            active={filter === "all"}
            onClick={() => setFilter("all")}
          >
            All
          </a>
          <a
            className="cursor-pointer"
            active={filter === "frontend"}
            onClick={() => setFilter("frontend")}
          >
            Frontend
          </a>

          <a
            className="cursor-pointer"
            active={filter === "ux-ui"}
            onClick={() => setFilter("ux-ui")}
          >
            UX/UI
          </a>
          <a
            className="cursor-pointer"
            active={filter === "others"}
            onClick={() => setFilter("others")}
          >
            Others
          </a>
        </div>

        <div className="mx-auto text-center px-0 w-full lg:grid-cols-4 justify-center flex-wrap flex max-w-[1225px]">
          {projects.map((item) =>
            item.filtered === true ? (
              <div
                className="cursor-pointer block flex-col m-3 p-1 col-span-1"
                key={item.name}
              >
                <span className="inline-grid"> {item.content} </span>
                <span className="block m-1"> {item.name} </span>
              </div>
            ) : (
              ""
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Portfolioarea;
