import React from "react";

const Footerbar = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="text-white bg-[#000300] text-center py-8">
      <p>Copyright &copy; {currentYear} All rights reserved </p>
    </div>
  );
};

export default Footerbar;
