import React, { useState, useEffect, useRef } from "react";
import AboutMe from "../assets/about-me.jpg";
import { Link } from "react-scroll";

const About = () => {
  const aboutme_area = useRef(null);

  return (
    <section id="aboutme">
      <div className="max-w-[1100px] py-20 mx-auto text-left px-4">
        <h1 className="w-full text-center font-bold text-3xl">WHO AM I?</h1>
        <p className="text-center py-4 text-lg text-[#7c7c7c]">
          designing & building websites that are always ahead of the curve
        </p>

        <div className="lg:flex content-center float-none pt-2 text-center md:text-left mx-auto">
          <img
            className="md:w-[320px] md:h-[320px] md:mt-0 lg:mt-2 mx-auto w-half"
            src={AboutMe}
          />
          <div className="flex-col md:mx-4 lg:w-half md:w-full sm:mx-6">
            <p className="py-5 leading-8 sm:text-center xs:text-center md:text-left">
              A highly experienced mid level Frontend Developer with years of
              expertise in developing user-friendly, adaptive and responsive
              websites & web applications with optimized cross-browser
              compatibility and runtime performance. Adept in collaborating with
              cross-functional teams and delivering projects on-time and
              on-budget. Proficient in collaborating with back-end teams to
              integrate user-facing elements with server-side logic,
              implementing automated testing platforms, and optimizing
              components for maximum performance. Skilled in identifying and
              resolving performance and scalability issues, staying up-to-date
              with emerging technologies and frameworks, and reducing
              development time for new projects.
            </p>
            <button className="bg-[#1357a9] block mx-auto lg:mx-0 md:mx-0 text-white text-sm px-4 py-2 rounded-md">
              <Link
                activeClass="active"
                to="skills"
                spy={true}
                smooth={true}
                offset={-95}
                duration={500}
                className=""
              >
                Read More
              </Link>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
