import React, { useState, useEffect, useRef } from "react";

const Resume = () => {
  const onButtonClick = () => {
    const pdfUrl = "Deepu_Resume.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Deepu_Mathew_Resume.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section id="resume">
      <div className="resume_area w-full bg-[#0570a9] pt-20 pb-24 px-4 text-white">
        <div className="max-w-[1250px] mx-auto text-center">
          <h1 className="text-4xl py-4">I'm available for projects</h1>
          <p className="py-1 font-light">
            always craving for new exciting opportunities, reach me out let's do
            something great together
          </p>
          <button
            onClick={onButtonClick}
            className="bg-teal-900 text-white px-5 py-3 mt-6 rounded-md mx-2"
          >
            DOWNLOAD RESUME
          </button>
          <button className="bg-yellow-600 px-5 py-3 mt-6 text-white rounded-md mx-2">
            <a
              target="_blank"
              href="https://www.linkedin.com/in/deepu-mathew-b0700919b?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BUgt9%2FEDDQJiPBzUo7yyvhw%3D%3D"
            >
              VIEW ME ON LINKEDIN
            </a>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Resume;
