import React, { useState, useEffect, useRef } from "react";
import { ReactTyped } from "react-typed";
import Introbg from "../assets/intro-bg.jpg";
import "./Navbar.jsx";
import { RiArrowDownDoubleLine } from "react-icons/ri";

const Intro = () => {
  const intro_area = useRef(null);

  return (
    <section id="intro">
      <div className="block w-full md:bg-hero bg-no-repeat bg-cover bg-[#0f0f0f] bg-center bg-fixed">
        <div className="text-white max-w-[800px] lg:pt-80 lg:pb-[180px] sm:pt-60 sm:pb-40 pt-40 pb-20 mx-auto w-full text-center flex flex-col">
          <p className="text-white text-xl">Hey there! I am</p>
          <h1 className="font-semibold md:text-5xl text-3xl py-6">
            DEEPU MATHEW
          </h1>

          <div className="flex justify-center text-2xl items-center py-2">
            <p>I'm a</p>

            <ReactTyped
              className="text-2xl md:pl-2 pl-2 text-[#2caff5]"
              strings={[
                "Front-End Developer",
                "React Developer",
                "JavaScript Developer",
                "UI Designer",
                "Graphic Designer",
              ]}
              typeSpeed={140}
              backSpeed={160}
              loop
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
