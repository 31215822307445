import React, { useState, useEffect, useRef } from "react";
import { FaRegAddressCard } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdOutlineMarkEmailRead } from "react-icons/md";

const Contact = () => {
  const contactme_area = useRef(null);

  return (
    <section id="contactme">
      <div className="max-w-[1250px] mx-auto pt-20 md:px-4 text-center px-4">
        <h1 className="font-bold text-3xl">CONTACT ME</h1>
        <p className="py-4 text-lg text-[#7c7c7c]">
          i'm always happy to hear from you, get in touch
        </p>

        <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 text-center mt-12 mx-auto">
          <div className="mx-2 px-10 py-12 shadow-xl block text-center object-center">
            <div className="rounded-full py-9 px-9 bg-[#1357a9] text-white text-3xl  w-[100px] h-[100px] mx-auto">
              <FaRegAddressCard />
            </div>
            <h5 className="font-bold text-xl pt-4 pb-4">Address</h5>
            <p>4902 Carol St Unit D, Skokie, IL 60077</p>
          </div>

          <div className="mx-2 px-10 py-12 shadow-xl block text-center object-center">
            <div className="rounded-full py-9 px-9 bg-[#1357a9] text-white text-3xl  w-[100px] h-[100px] mx-auto">
              <FaPhoneVolume />
            </div>
            <h5 className="font-bold text-xl pt-4 pb-4">Phone Number</h5>
            <p>+1 224-432-8883</p>
          </div>

          <div className="mx-2 px-10 py-12 shadow-xl block text-center object-center">
            <div className="rounded-full py-9 px-9 bg-[#1357a9] text-white text-3xl  w-[100px] h-[100px] mx-auto">
              <MdOutlineMarkEmailRead />
            </div>
            <h5 className="font-bold text-xl pt-4 pb-4">Email Address</h5>
            <p>deepumathew89@gmail.com</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
