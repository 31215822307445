import React, { useState, useEffect, useRef } from "react";

const Skillsbar = () => {
  const skills_area = useRef(null);

  return (
    <section id="skills">
      <div className="bg-[#eee]">
        <div className="max-w-[1100px] py-20 mx-auto text-center md:px-4 px-4">
          <h1 className="w-full text-center font-bold text-3xl">SKILLS</h1>
          <p className="py-4 text-lg text-[#7c7c7c]">
            innovative website development using latest technologies
          </p>

          <div className="grid lg:grid-cols-4 sm:grid-cols-2 xs:grid-cols-1 pt-6">
            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">HTML</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[98%]">
                  {" "}
                  98%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">CSS</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-teal-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[98%]">
                  {" "}
                  98%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">JavaScript</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-red-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[85%]">
                  {" "}
                  85%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">React</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-green-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[73%]">
                  {" "}
                  73%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">Tailwind CSS</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-cyan-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[95%]">
                  {" "}
                  95%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">BootStrap</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-yellow-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[95%]">
                  {" "}
                  95%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">Material UI</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-pink-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[67%]">
                  {" "}
                  67%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">Java</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-violet-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[42%]">
                  {" "}
                  42%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">Python</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-teal-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[33%]">
                  {" "}
                  33%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">C</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-pink-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[54%]">
                  {" "}
                  54%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">C++</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-green-800 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[58%]">
                  {" "}
                  58%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">SQL</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-yellow-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[42%]">
                  {" "}
                  47%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">PhotoShop</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[94%]">
                  {" "}
                  94%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">UI Designing</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-red-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[86%]">
                  {" "}
                  86%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">Responsive Web Designing</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-green-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[97%]">
                  {" "}
                  97%{" "}
                </div>
              </div>
            </div>

            <div className="w-full py-4 px-4 col-span-1">
              <p className="text-left py-3 px-1">OOP</p>
              <div className="w-full bg-gray-200 rounded-full">
                <div className="bg-cyan-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full w-[77%]">
                  {" "}
                  77%{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skillsbar;
