import React, { useState, useRef, useEffect } from "react";
import Logo from "../assets/logo.png";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { FaPhoneVolume } from "react-icons/fa6";
import { Link } from "react-scroll";
import { GoSun } from "react-icons/go";
import { IoMoonOutline } from "react-icons/io5";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    console.log(window.scrollY);
    if (window.scrollY >= 530) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  const [theme, setTheme] = useState(false);

  return (
    <header>
      <nav
        className={
          navbar
            ? "fixed z-40 px-6 bg-[#2771ae] max-w-[1530px] block w-full"
            : "bg-[#1b1a1a] w-full px-6 fixed max-w-[1530px] z-40"
        }
      >
        <div className="flex justify-between items-center h-20 lg:h-24 w-full mx-auto xl:px-[50px] text-white">
          <span className="w-half w-[65px]">
            <Link
              activeClass="active"
              to="intro"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="cursor-pointer"
            >
              <img className="w-[56px] lg:w-[60px]" src={Logo} />
            </Link>
          </span>

          <div className="hidden justify-between md:flex xl:pl-[150px] lg:pl-[50px]">
            <Link
              activeClass="active"
              to="intro"
              spy={true}
              smooth={true}
              offset={-95}
              duration={500}
              className="p-4 px-6 cursor-pointer"
            >
              Home
            </Link>
            <Link
              activeClass="active"
              to="aboutme"
              spy={true}
              smooth={true}
              offset={-95}
              duration={500}
              className="p-4 px-6 cursor-pointer"
            >
              About Me
            </Link>
            <Link
              activeClass="active"
              to="skills"
              spy={true}
              smooth={true}
              offset={-95}
              duration={500}
              className="p-4 px-6 cursor-pointer"
            >
              Skills
            </Link>
            <Link
              activeClass="active"
              to="portfolioarea"
              spy={true}
              smooth={true}
              offset={-95}
              duration={500}
              className="p-4 px-6 cursor-pointer"
            >
              Portfolio
            </Link>
            <Link
              activeClass="active"
              to="resume"
              spy={true}
              smooth={true}
              offset={-95}
              duration={500}
              className="p-4 px-6 cursor-pointer"
            >
              Resume
            </Link>
            <Link
              activeClass="active"
              to="contactme"
              spy={true}
              smooth={true}
              offset={-95}
              duration={500}
              className="p-4 px-6 cursor-pointer"
            >
              Contact Me
            </Link>
          </div>

          <div className="p-4 px-6 text-md hidden lg:block xl:block">
            <span className="py-1 px-2 float-left text-[#ffffff]">
              <FaPhoneVolume />
            </span>
            <h5 className="float-left">+1 224-432-8883</h5>
          </div>

          {/* Mobile Menu Start */}
          <div onClick={handleNav} className="block md:hidden px-3">
            {!nav ? <AiOutlineMenu size={25} /> : <AiOutlineClose size={25} />}
          </div>

          <div
            className={
              nav
                ? "fixed left-0 top-0 pt-16 w-[55%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500"
                : "fixed left-[-100%]"
            }
          >
            <div className="uppercase">
              <Link
                onClick={handleNav}
                activeClass="active"
                to="intro"
                spy={true}
                smooth={true}
                offset={-95}
                duration={500}
                className="py-4 px-10 block w-full border-b text-xs border-gray-600"
              >
                Home
              </Link>
              <Link
                onClick={handleNav}
                activeClass="active"
                to="aboutme"
                spy={true}
                smooth={true}
                offset={-95}
                duration={500}
                className="py-4 px-10 block w-full border-b text-xs border-gray-600"
              >
                About Me
              </Link>
              <Link
                onClick={handleNav}
                activeClass="active"
                to="skills"
                spy={true}
                smooth={true}
                offset={-95}
                duration={500}
                className="py-4 px-10 block w-full border-b text-xs border-gray-600"
              >
                Skills
              </Link>
              <Link
                onClick={handleNav}
                activeClass="active"
                to="portfolioarea"
                spy={true}
                smooth={true}
                offset={-95}
                duration={500}
                className="py-4 px-10 block w-full border-b text-xs border-gray-600"
              >
                Portfolio
              </Link>
              <Link
                onClick={handleNav}
                activeClass="active"
                to="resume"
                spy={true}
                smooth={true}
                offset={-95}
                duration={500}
                className="py-4 px-10 block w-full border-b text-xs border-gray-600"
              >
                Resume
              </Link>
              <Link
                onClick={handleNav}
                activeClass="active"
                to="contactme"
                spy={true}
                smooth={true}
                offset={-95}
                duration={500}
                className="py-4 px-10 block w-full border-b text-xs border-gray-600"
              >
                Contact Me
              </Link>
            </div>
          </div>
          {/* Mobile Menu End */}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
